"use client";

import {useEffect} from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import useUserStore from "@business/components/common/UserModel";

if (process.env.NEXT_PUBLIC_BUGSNAG_ENABLED === "true") {
    Bugsnag.start({
        appVersion: process.env.NEXT_PUBLIC_BUGSNAG_APP_VERSION,
        apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || "",
        releaseStage: process.env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE,
        plugins: [new BugsnagPluginReact()],
    });
}

export default function BugSnagNotification({error, errorBoundary}: {error: Error; errorBoundary: string}) {
    const userStore = useUserStore();
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_BUGSNAG_ENABLED === "true") {
            Bugsnag.notify(error, function (event) {
                event.addMetadata("nextJs", {errorBoundary});
                if (userStore.isAuthenticated()) {
                    // @ts-expect-error user object imported from an untyped .js file
                    const {id, email, firstName, lastName} = user;
                    event.setUser(id, email, `${firstName} ${lastName}`);
                }
            });
        }
    }, [error]);

    return null;
}
