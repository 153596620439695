import React from "react";

import Paragraph from "@ng-components/primitives/Paragraph";

export default function ErrorPageDescription({children, "data-cy": dataCy}: ErrorPageComponentProps) {
    return (
        <Paragraph data-cy={dataCy} justify="center">
            {children}
        </Paragraph>
    );
}
