import React from "react";

import Section from "@ng-components/primitives/Section";
import Stack from "@ng-components/primitives/Stack";

export default function ErrorPageSection({children, "data-cy": dataCy}: ErrorPageComponentProps) {
    return (
        <Section data-cy={dataCy} verticalAlign="center" verticalFill={true}>
            <Stack>{children}</Stack>
        </Section>
    );
}
