import React from "react";

import Title from "@ng-components/primitives/Title";

export default function ErrorPageTitle({children, "data-cy": dataCy}: ErrorPageComponentProps) {
    return (
        <Title data-cy={dataCy} justify="center" level={1} size="medium">
            {children}
        </Title>
    );
}
