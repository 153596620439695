import React from "react";

interface ErrorPageAnimationProps {
    fileUrl: string;
    width?: number;
    height?: number;
}

export default function ErrorPageAnimation({fileUrl, width = 200, height = 200}: ErrorPageAnimationProps) {
    return (
        <video
            className="ng-error-page-animation"
            autoPlay={true}
            loop={true}
            width={width}
            height={height}
            src={fileUrl}
        />
    );
}
